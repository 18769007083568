<template>
	<div class="bg-beige rounded-lg">
		<!-- <pre class="overflow-scroll h-100">{{ transaction }}</pre> -->
		
		<div class="flex p-4">
			<div class="flex-grow grid grid-cols-2 gap-6">
				<div class="">
					<strong>Tidspunkt</strong>
					<div class="">
						{{ transaction.createdAt  | moment('lll') }}
					</div>
				</div>
				
				<div v-if="type == 'user'">
					<strong>Bruker</strong>
					<div class="truncate">
						{{username || 'Ukjent'}}
					</div>
				</div>
				<div v-else-if="type == 'department'">
					<strong>Avdeling</strong>
					<div class="truncate">
						{{departments && departments.find( d => d.departmentId == transaction.departmentId)? departments.find( d => d.departmentId == transaction.departmentId).name : 'Ukjent' }}
						<!-- {{transaction.departmentId}} -->
					</div>
				</div>
			</div>
			<div class="flex-none flex space-x-2 mt-1">
				<slot></slot>
				<!-- <i 
					class="fas fa-edit w-8 text-lg text-center " 
					@click="showEdit = true"
				/>
				<i 
					class="fas fa-trash w-8 text-lg text-center " 
					@click="onDeleteTransaction()"
				/> -->
			</div>
		</div>

		<div class="">
			<table v-if="transaction.products && transaction.products.length">
				<template v-for="p in transaction.products">
					<tr :key="'product_'+transaction.transactionId+'_'+p.productId">
						<td 
							class="bg-white truncate"
							style="max-width:1px;">
							
							{{p.name}}
							
							<em 
								v-if="p.comment && p.comment != ''"
								class="block opacity-75 truncate">
								
								{{p.comment}}
							</em>
						</td>
						<td class="text-right w-2/12">
							<div class="whitespace-no-wrap">
								á {{p.value}},-
							</div>
						</td>
						<td class="text-center bg-white w-2/12">
							<div class="whitespace-no-wrap">
								{{p.qty}} stk
							</div>
						</td>
						<td class="text-right w-2/12">
							<div class="whitespace-no-wrap">
								{{p.value * p.qty}},-
							</div>
						</td>
					</tr>
				</template>
				<tfoot v-if="transaction.products.length > 1">
					<tr>
						<td class="bg-white">Total</td>
						
						<td class="text-center w-2/12"></td>
						<td class="text-center bg-white w-2/12">
							<div class="whitespace-no-wrap">
								{{totalQty( transaction, 'products' )}} stk
							</div>
						</td>
						<td class="text-right w-2/12">
							<div class="whitespace-no-wrap">
								{{totalValueSum(transaction, 'products')}},-
							</div>
						</td>
						
						<!-- <td class="text-center w-2/12">{{totalQty( transaction )}}</td>
						<td class="text-right bg-white w-2/12">{{totalValue( transaction )}},-</td>
						<td class="text-right w-2/12">{{totalValue( transaction ) * totalQty( transaction )}},-</td> -->
					</tr>
				</tfoot>
			</table>
			
			<table v-if="transaction.tasks && transaction.tasks.length">
				<template v-for="t in transaction.tasks">
					<tr :key="'task_'+transaction.transactionId+'_'+t.taskId">
						<td 
							class="bg-white truncate"
							style="max-width:1px;">
							
							{{t.name}}
							
							<em 
								v-if="t.comment && t.comment != ''"
								class="block opacity-75">
								
								{{t.comment}}
							</em>
						</td>
						<td class="text-center w-2/12">{{t.qty}} stk</td>
					</tr>
				</template>
				<tfoot v-if="transaction.tasks.length > 1">
					<tr>
						<td class="bg-white">Total</td>
						<td class="text-center w-2/12">
							<div class="whitespace-no-wrap">
								{{totalQty( transaction, 'tasks' )}} stk
							</div>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		
	</div>
</template>

<script>
export default {
	props: { 
		transaction: {
			type: Object,
			required: true,
		},
		
		type: {
			type: String,
			required: true,
		},
		
		departments: {
			type: Array,
			required: false,
		},
		
		users: {
			type: Array,
			required: false,
		},
	},
	
	computed: {
		username(){
			try {
				let user;
				const userFound = this.users.find( u => u.username == this.transaction.username);
				
				if ( userFound ) {
					user = `${userFound.given_name} ${userFound.family_name}`;
				}
				
				return user
			} 
			catch (e) {
				return null;
			}
		},
	},
	
	data(){
		return {
			loading: null,
			showEdit: false,
		}
	},
	
	methods: {
		totalQty( transaction, entity = 'products' ){
			let total = 0;
			transaction[entity].forEach( p => {
				total += Number(p.qty);
			});
			
			return total;
		},
		
		totalValue( transaction, entity = 'products' ){
			let total = 0;
			transaction[entity].forEach( p => {
				total += Number(p.value);
			});
			
			return total;
		},
		
		totalValueSum( transaction, entity = 'products' ){
			let total = 0;
			transaction[entity].forEach( p => {
				total += Number(p.value) * Number(p.qty);
			});
			
			return total;
		},
		
		
	},
}
</script>